<script setup>

const config = useRuntimeConfig()
const themeStore = useThemeStore()
</script>

<template>
  <div class="mt-0 layout-footer flex align-items-center justify-content-center border-0" style="background: linear-gradient(to right, var(--surface-card) 20%, transparent);">
    <!-- 3 col, prima vuota per segnaposto, la seconda app name centrale, la terza in fondo per l'imagine-->
    <div class="col-2" />
    <div class="col text-center">
      <span class="font-medium">{{ `${appConfig.infoapp.name}` }}</span>
    </div>
    <div class="col-2 text-right">
      <img
        :src="themeStore.isDarkMode ? `/images/home_icone/logo_footer_tema_scuro.png`: `/images/home_icone/logo_footer_tema_chiaro.png`"
        alt="Image"
        class="ml-2"
      >
    </div>
    <!-- <span class="font-medium ml-2 col text-right">{{ `${appConfig.infoapp.name}` }}</span>
    <span id="image-right" class="ml-2 col-2">
      <img :src="`/images/home_icone/logo_footer_tema_scuro.png`" alt="Image" class="ml-2" style="width: 80px;">
    </span> -->
  </div>
</template>
<style lang="scss">
.layout-footer {
  background: linear-gradient(to right, var(--surface-card) 90%, transparent);
}
</style>
